import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { IBasicInfo } from 'types/helloKlarity';
import { getProviderNameDisplay } from 'utils/provider';
import { EMPTY_PLACEHOLDER } from 'constants/common';
import { Button, Form, Input, message, Modal, Tooltip } from 'antd';
import AiTextEditor from 'components/AI/AiTextEditor';
import { EAIEditorType, EConfigMoudleType } from 'types/ai';
import { getAiAboutMe, getAiHeadlines } from 'api/ai';
import { EditModuleEnum } from 'types/practiceFront';
import commonS from 'styles/common.module.less';

interface IProps {
    conditionId?: string;
    initFormValue: IBasicInfo;
    onSubmit?: (formValue: IBasicInfo, field: EditModuleEnum, callback?: () => void) => void;
}

const SpecificBasicInfo = ({
    conditionId,
    initFormValue,
    onSubmit,
}: IProps) => {
    const { id, firstName, lastName, middleName, credential, title, photo, headLine, intro } = initFormValue || {};
    const providerName = getProviderNameDisplay({
        firstName,
        lastName,
        middleName,
    });
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [isAiAboutMeLoading, setIsAiAboutMeLoading] = useState(false);
    const [isAiHeadlinesLoading, setIsAiHeadlinesLoading] = useState(false);
    const headlinesMaxLength = 85;
    const aboutMeMaxLength = 900;

    useEffect(() => {
        form.setFieldsValue({
            name: providerName,
            title,
            credentials: credential?.join(','),
            headLines: headLine?.slice(0, headlinesMaxLength),
            aboutMe: intro?.slice(0, aboutMeMaxLength),
        });
    }, [form, providerName, title, credential, headLine, intro]);

    const handleGetAiAboutMe = async () => {
        if (id && conditionId) {
            setIsAiAboutMeLoading(true);
            try {
                const res = await getAiAboutMe(id, conditionId);
                if (res && !res.error) {
                    form.setFieldsValue({
                        aboutMe: aboutMeMaxLength && aboutMeMaxLength > 0 ? res.data.data.slice(0, aboutMeMaxLength) : res.data.data,
                    });
                } else {
                    message.error(res?.error);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsAiAboutMeLoading(false);
            }
        }
    };

    const handleGetAiHeadlines = async () => {
        if (id && conditionId) {
            setIsAiHeadlinesLoading(true);
            try {
                const res = await getAiHeadlines(id, conditionId);
                if (res && !res.error) {
                    form.setFieldsValue({
                        headLines: headlinesMaxLength && headlinesMaxLength > 0 ? res.data.data.slice(0, headlinesMaxLength) : res.data.data,
                    });
                } else {
                    message.error(res?.error);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsAiHeadlinesLoading(false);
            }
        }
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            onSubmit?.(values, EditModuleEnum.SPECIFIC_ABOUT, () => {
                setModalVisible(false);
            });
        });
    };

    return (
        <div className={s.wrap}>
            <Modal
                className={commonS.modalFixHeightWrap}
                title="Edit AI-generated basic info"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={handleSubmit}
                destroyOnClose
            >
                <div className={s.modalWrap}>
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Form.Item label="Name" name="name">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="Title" name="title">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="Credentials" name="credentials">
                            <Input disabled />
                        </Form.Item>
                        <Form.Item label="Headlines" name="headLines">
                            <AiTextEditor
                                showCount
                                maxLength={headlinesMaxLength}
                                forceEnabled
                                isOps
                                hideConfigActions
                                staticAIActions={
                                    [
                                        {
                                            iconUrl: 'https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/icon-ai-write-like.svg',
                                            name: 'AI generate',
                                            description: 'Click to regenerate content based on the provider’s profile.',
                                            type: EConfigMoudleType.TEXT,
                                            id: -1,
                                            canSubmit: true,
                                            loading: isAiHeadlinesLoading,
                                            onClick: handleGetAiHeadlines,
                                        },
                                    ]
                                }
                                textAreaClass={s.aiTextEditorTextArea}
                                placeholder=""
                                wrapClass={s.aiTextEditor}
                                type={EAIEditorType.HEADLINES}
                            />
                        </Form.Item>
                        <div className={s.item}>
                            <div className={s.label}>Profile photo</div>
                            <div className={s.value}>
                                <img className={s.modalAvatar} src={photo} />
                            </div>
                        </div>
                        <Form.Item label="About me" name="aboutMe">
                            <AiTextEditor
                                showCount
                                maxLength={aboutMeMaxLength}
                                forceEnabled
                                isOps
                                hideConfigActions
                                staticAIActions={
                                    [
                                        {
                                            iconUrl: 'https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/ui/icon-ai-write-like.svg',
                                            name: 'AI generate',
                                            description: 'Click to regenerate content based on the provider’s profile.',
                                            type: EConfigMoudleType.TEXT,
                                            id: -2,
                                            canSubmit: true,
                                            loading: isAiAboutMeLoading,
                                            onClick: handleGetAiAboutMe,
                                        },
                                    ]
                                }
                                wrapClass={s.aiTextEditor}
                                textAreaClass={s.aiTextEditorTextArea}
                                type={EAIEditorType.ABOUTME}
                                placeholder=""
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <div className={s.header}>
                <div className={s.title}>
                    <div className={s.titleText}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
                            <path d="M4.9942 10.7388C5.10549 10.4381 5.53088 10.4381 5.64217 10.7388L6.42936 12.8662C6.46435 12.9607 6.5389 13.0353 6.63346 13.0703L8.76081 13.8575C9.06157 13.9688 9.06157 14.3942 8.76081 14.5054L6.63346 15.2926C6.5389 15.3276 6.46435 15.4022 6.42936 15.4967L5.64217 17.6241C5.53088 17.9248 5.10549 17.9248 4.9942 17.6241L4.20701 15.4967C4.17202 15.4022 4.09746 15.3276 4.00291 15.2926L1.87556 14.5054C1.5748 14.3942 1.5748 13.9688 1.87556 13.8575L4.00291 13.0703C4.09746 13.0353 4.17202 12.9607 4.20701 12.8662L4.9942 10.7388Z" fill="url(#paint0_linear_23791_28846)" />
                            <path d="M11.2858 2.31794C11.4533 1.86522 12.0936 1.86522 12.2611 2.31794L13.446 5.52016C13.4987 5.6625 13.6109 5.77472 13.7533 5.82739L16.9555 7.01232C17.4082 7.17984 17.4082 7.82016 16.9555 7.98768L13.7533 9.17261C13.6109 9.22528 13.4987 9.3375 13.446 9.47984L12.2611 12.6821C12.0936 13.1348 11.4533 13.1348 11.2858 12.6821L10.1008 9.47984C10.0482 9.3375 9.93594 9.22528 9.7936 9.17261L6.59138 7.98768C6.13866 7.82016 6.13866 7.17984 6.59138 7.01232L9.7936 5.82739C9.93594 5.77472 10.0482 5.6625 10.1008 5.52016L11.2858 2.31794Z" fill="url(#paint1_linear_23791_28846)" />
                            <defs>
                                <linearGradient id="paint0_linear_23791_28846" x1="2.22254" y1="11.1022" x2="7.15222" y2="18.0588" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#02FF74" />
                                    <stop offset="0.359375" stopColor="#0BCA88" />
                                    <stop offset="1" stopColor="#000AF0" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_23791_28846" x1="7.11368" y1="2.86493" x2="14.5342" y2="13.3364" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#02FF74" />
                                    <stop offset="0.359375" stopColor="#0BCA88" />
                                    <stop offset="1" stopColor="#000AF0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        AI-generated basic info
                    </div>
                    <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#000000" title={<div className={s.tipText}>{'AI-generated content based on the provider\'s Uniprofile, tailored for the selected condition.'}</div>}>
                        <div className={s.questionMarkIcon} />
                    </Tooltip>
                </div>
                <Button onClick={() => setModalVisible(true)}>
                    Edit
                </Button>
            </div>
            <div className={s.body}>
                <div className={s.viewWrap}>
                    <div className={s.viewItem}>
                        <div className={s.label}>Name</div>
                        <div className={s.value}>{providerName || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Title</div>
                        <div className={s.value}>{title || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Credentials</div>
                        <div className={s.value}>{credential?.join(',') || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Headlines</div>
                        <div className={s.value}>{headLine || EMPTY_PLACEHOLDER}</div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>Profile photo </div>
                        <div className={s.value}>
                            {
                                photo ? <img className={s.avatar} src={photo} /> : EMPTY_PLACEHOLDER
                            }
                        </div>
                    </div>
                    <div className={s.viewItem}>
                        <div className={s.label}>About me </div>
                        <div className={s.value} dangerouslySetInnerHTML={{ __html: intro }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecificBasicInfo;
